<template>
  <div class = "module module-full-flex">
    <div>
      <router-link :to = "data.link">
        <div class = "module-top-image image-height" :style = "getBackgroundImage(data.image)">
          <div class = "module-top-bar gradient-bar-alt">
            <h3>{{ data.title }}</h3>
          </div>
        </div>
      </router-link>
    </div>
    <div>
      <p>{{ data.text }}</p>
    </div>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue'

  defineProps(['data'])

  function getBackgroundImage(image) {
    return {backgroundImage: 'url("' + image + '")'}
  }
</script>

<style>
  .image-height {
    height: 11em;
  }
</style>
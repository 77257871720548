<template>
  <section class = "showcase module">
    <div v-none class = "showcase-image" :style = "currItem.styleBg" :title = "currItem.title"></div>
    <div class = "showcase-content">
      <div class = "icon-bar">
        <ul>
          <li @click = "selectItem(index)" v-for = "(item, index) in data" :id = "'showcaseItem' + index" :class = "{fade: true, ['div-active']: isSelected(item.title)}" :key = "item.title">
            <img v-if = "item.icon" :src = "getIcon(item.icon)" :alt = "item.title"/>
          </li>
        </ul>
      </div>
      <div class = "description-bar gradient-bar-alt">
        <div class = "item-title">{{ currItem.title }}</div>
        <div class = "item-description"> {{ currItem.text }}</div>
        <div class = "item-button">
          <router-link class = "button button-strong button-square float-right" :to = "currItem.link">Go</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import { reactive, defineProps, onMounted } from 'vue'
  const axios = require('axios')

  const props = defineProps(['data'])
  const currItem = reactive({title: '', text: '', link: '', image: '', styleBg: {}})
  var loadedImages = {}
  const isSelected = (title) => currItem.title == title

  function selectItem(index) {
    var obj = props.data[index]
    currItem.title = obj.altTitle || obj.title
    currItem.text = obj.text
    currItem.link = obj.link
    currItem.styleBg.filter = 'brightness(0%)'

    async function assertImage(image) {
      const imagePromise = new Promise(function(resolve) {
        setInterval(function () {
          if (loadedImages[image]) {
            resolve(image)
          }
        }, 200) // 200 ms allows filter transition to work fully
      })
      
      var result = await(imagePromise)
      currItem.styleBg.backgroundImage = 'url("' + result + '")'
      currItem.styleBg.filter = 'brightness(100%)'
    }

    assertImage(obj.image);
  }

  function getIcon(icon) { //TODO: make this a common function
    return require('@/assets/icons/' + icon + '.svg')
  }

  onMounted(() => {
    props.data.forEach(function (value) {
      loadedImages[value.image] = false
      if (value.image) {
        axios.get(value.image) // cache images
        .then(function () {
          loadedImages[value.image] = true
        })
      }
    })

    selectItem(0) // selects the first item automatically
  })
</script>

<style lang = "scss" scoped>
  @use "@/main.scss";

  .showcase {
    overflow: hidden;
    position: relative;
    width: auto;
    height: 24em;
    margin: 0 -20px;
  }
  .showcase-image {
    width: 100%;
    height: 100%;
    background-position: center; 
    background-size: cover;
    transition: filter 0.2s linear;
  }
  .showcase-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .icon-bar {
    margin-top: 18em;
    height: 3em;
  }
  .icon-bar ul {
    display: flex;
    flex-wrap: none;
    justify-content: center;
    gap: 0 30px;
  }
  .icon-bar li {
    flex: 0 0 auto;
    margin: 0;
    font-size: 1em;
    line-height: 3em;
    list-style-type: none;
  }
  .icon-bar li img{
    display: inline-block;
    width: 24px;
    height: 24px;
    max-width: 40px;
    max-height: 40px;
    margin: auto;
    vertical-align: middle;
    background-color: black;
    border: solid 2px black;
    border-radius: 50%;
    transition: all 0.2s linear;
  }
  .icon-bar li img:hover{
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .description-bar {
    display: flex;
    flex-wrap: none;
    justify-content: right;
    gap: 0 20px;
    height: 3em;
  }
  .item-title {
    flex: 0 0 auto;
    padding-left: 20px;
    width: 220px;
    font-size: 1.563em;
    line-height: 1.25em;
    margin: 0.523rem 0;
    text-transform: uppercase;
  }
  .item-description {
    flex: 1 1 auto;
  }
  .item-button {
    flex: 0 0 auto;
  }
  .button-square {
    margin: 0;
    min-width: 48px;
    min-height: 3em;
    font-weight: normal;
  }

  @media screen and (max-width: 600px) {
    .item-description {
      visibility: hidden;
    }
  }
</style>
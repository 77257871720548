<template>
  <div class = "module module-medium-stretch thumbnail">
    <div @click = "$emit('open-screen-box', num)" class = "module-top-image image-height" :style = "getThumbnailImage(data.image)">
      <div v-if = "data.titles" class = "module-top-bar gradient-bar-alt">
        <span class = "module-title">{{ data.title }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue'

  defineProps(['data', 'num'])

  //TODO: add lazy loading here (if thumbnail is visible)
  function getThumbnailImage(image) {
    var split = image.split('/')
    var result = ''
    for (var i = 1; i < split.length; i++) {
      if (i == split.length - 1) {
        result = result + '/small'
      }
      result = result + '/' + split[i] 
    }
    return {backgroundImage: 'url("' + result + '")'}
  }
</script>

<style>
  .image-height {
    height: 8;
  }
  .thumbnail:hover {
    cursor: pointer;
  }
</style>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProjectsView from '../views/ProjectsView.vue'
import OtherView from '../views/OtherView.vue'
import SandboxView from '../views/SandboxView.vue'
import DemoView from '../views/demo/DemoView.vue'
import ErrorPage from '../views/ErrorPage.vue'

import StepmaniaView from '../views/projects/StepmaniaView.vue'
import BeamngView from '../views/projects/BeamngView.vue'
import PhotographyView from '../views/projects/PhotographyView.vue'

import BeatApp from '../views/apps/BeatApp.vue'
import ImageListTestApp from '../views/apps/ImageListTestApp.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    alias: '/home',
    component: HomeView // TODO: lazy loading! () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView
  },
  {
    path: '/other',
    name: 'other',
    component: OtherView
  },
  {
    path: '/z',
    name: 'sandbox',
    component: SandboxView
  },
  {
    path: '/demo',
    name: 'demo',
    component: DemoView
  },
  {
    path: '/apps/imageListTest',
    name: 'imageList',
    component: ImageListTestApp
  },
  {
    path: '/projects/stepmania',
    name: 'stepmania',
    component: StepmaniaView
  },
  {
    path: '/projects/beamng',
    name: 'beamng',
    component: BeamngView
  },
  {
    path: '/projects/photography',
    name: 'photography',
    component: PhotographyView
  },
  {
    path: '/apps/beat',
    name: 'beat',
    component: BeatApp
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

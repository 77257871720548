<template>
  <div>
    <img class = "margin" src = "/main/error-404.svg" alt = "Error"/>
    <h2>Error 404</h2>
    <p>Sorry, this page does not exist.</p>
    <a class = "button button-strong" href = "#" @click="back">Back</a>
  </div>
</template>

<script setup>
  const back = () => history.back()
</script>
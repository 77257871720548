<template>
  <div>
    <h2>Greetings, {{ name }}</h2>
    <div>
      <img class="float-left" width="300" :src="contents[idx].src"/>
      <h3>{{ contents[idx].name }}</h3>
      <p>{{ contents[idx].desc }}</p>
      <div class="float-clear"></div>
    </div>
    <div class = "button button-strong button-go" @click = "goNext">Next</div>
  </div>
</template>

<script setup>
  import {ref} from 'vue'

  const name = "User"
  const contents = [
    {src: require('@/temp/demo1.jpg'), name: "Gull", desc: "Gull lol"},
    {src: require('@/temp/demo2.jpg'), name: "Crow", desc: "Crow"},
    {src: require('@/temp/demo3.jpg'), name: "Canada Goose", desc: "Canada Goose"},
    {src: require('@/temp/demo4.jpg'), name: "Great Blue Heron", desc: "Great Blue Heron"}
  ]
  var idx = ref(0)

  function goNext() {
    idx.value = idx.value + 1
    if (!contents[idx.value]) idx.value = 0
  }

</script>

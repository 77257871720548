<template>
  <header class = "pattern-bg">
    <div class = "header-block">
      <div class = "header-banner">
        <router-link to = "/">
          <img class = "header-image float-left" src = "@/assets/styles/gull.png"/>
        </router-link>
        <span class = "header-title">Gullbase</span>
      </div>
      <nav class = "header-nav gradient-bar">
        <router-link v-for = "obj in links" :class = "{fade: true, current: isCurrent(obj.text)}" :to = "obj.link" :key = "obj.text">{{ obj.text }}</router-link>
      </nav>
    </div>
  </header>
</template>

<script setup>
  import { ref, watch } from 'vue'
  import { useRoute } from 'vue-router'

  const links = [{link: '/', text: 'Home'}, {link: '/about', text: 'About'}, {link: '/projects', text: 'Projects'}, {link: '/other', text: 'Other'}]
  const route = useRoute()
  const currentRoute = ref('')
  const isCurrent = (text) => text.toLowerCase() == currentRoute.value

  watch(route, async(route) => {
    currentRoute.value = route.name
  })
</script>

<style scoped>
  header {
    display: block;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 9em;
  }
  .header-block {
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 980px;
    height: 100%;
  }
  .header-banner {
    display: block;
    padding-left: 20px;
    width: 100%;
    height: 100%;
    user-select: none;
  }
  .header-image {
    display: inline-block;
    margin-top: 2em;
    width: auto;
    height: 5em;
    filter: brightness(100%);
    transition: filter 0.2s;
  }
  .header-image:hover {
    filter: brightness(140%);
  }
  .header-title {
    color: rgba(0, 0, 0, 0.25);
    font-family: Aero_03, Arial, sans-serif;
    font-size: 5em;
    line-height: 1.8em;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
    animation-name: headerTextPulse;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .header-nav {
    position: absolute;
    bottom: 0;
    z-index: 10;
  }
  .header-nav a {
    width: 12.5%;
  }
  .current {
    color: var(--text-link-c);
  }
  .current:hover {
    color: var(--text-link-a);
  }

  @media screen and (max-width: 600px) {
    .header-nav a {
      width: 25%;
    }
    .header-title {
      font-size: 3em;
      line-height: 3em;
    }
  }
</style>
<template>
  <div>
    <h2>Images Test</h2>
    <p>Select an image to add to the list.</p>
    <form>
      <input class = "fileInput" type = "file" accept = "image/*" @change = "updateImage"/>
      <img class = "imagePreview" v-if = "current.url" :src = "current.url" alt = "Image preview" />
      <label for = "prefix">Prefix:</label>
      <input id = "prefix" type = "text" v-model = "current.prefix"/>
      <label for = "title">Title:</label>
      <input id = "title" type = "text" v-model = "current.title"/>
      <label for = "description">Description:</label>
      <input id = "description" type = "text" v-model = "current.description"/>
      <label for = "tags">Tags (separated by commas):</label>
      <input id = "tags" type = "text" v-model = "current.tags"/>
      <a class = "button button-strong" @click = "apply">Add</a>
    </form>
    <p><strong>List ({{ allData.length }})</strong></p>
    <table class = "results">
      <tr v-for = "(item, index) in allData" :data = "item" :key = "index">
        <td><img class = "imagePreview" :src = "item.url" alt = "Image preview" /></td>
        <td>{{ item.filename }}</td>
        <td>{{ item.title }}</td>
        <td>[{{ item.tags.length }}]</td>
      </tr>
    </table>
    <p><strong>Raw JSON data</strong></p>
    <textarea rows = "20" :value = "output"></textarea>
    <a class = "button button-strong" @click = "copyJson">Copy</a>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue'
  const allData = ref([])
  const current = reactive({'url': undefined, 'timestamp': 0, 'prefix': '', 'image': '', 'title': '', 'description': '', 'tags': ''})
  const output = ref('')

  function updateImage(event) {
    if (event.target.files && event.target.files[0]) {
      current.url = URL.createObjectURL(event.target.files[0])
      current.image = event.target.files[0].name
      current.timestamp = Math.ceil(event.target.files[0].lastModified / 1000)
    }
  }

  function copyJson() {
    navigator.clipboard.writeText(output.value)
  }

  function clear() {
    current.url = undefined
    current.image = ''
    current.timestamp = 0
    current.title = ''
    current.description = ''
    current.tags = ''
  }

  function encode() {
    var allDataCopy = []
    for (var value of allData.value) {
      allDataCopy.push({
        'image': value.image,
        'timestamp': value.timestamp,
        'title': value.title.length > 0 ? value.title : undefined,
        'description': value.description.length > 0 ? value.description : undefined,
        'tags': value.tags
      })
    }
    output.value = JSON.stringify(allDataCopy, null, 2)
  }

  function apply() {
    if (current.url) { // one file at a time
      var tagsArray = current.tags.length > 0 ? current.tags.split(',') : []
      allData.value.push({
        'url': current.url,
        'image': current.prefix + current.image,
        'filename': current.image,
        'timestamp': current.timestamp,
        'title': current.title,
        'description': current.description,
        'tags': tagsArray
      })
      encode()
    }
    clear()
  }
</script>

<style scoped>
  input[type=file] {
    display: block;
    margin: 0.5em 0;
  }
  input[type=text] {
    display: block;
    width: 50%;
    min-width: 200px;
    margin: 0.5em 0;
    padding: 10px;
  }
  input[type=button] {
    display: block;
    margin: 0.5em 0;
    padding: 10px;
  }
  textarea {
    display: block;
    width: 50%;
    min-width: 200px;
  }
  .results td {
    padding-right: 20px;
    min-width: 100px;
    text-align: left;
    vertical-align: middle;
  }
  .imagePreview {
    display: block;
    width: 100px;
    height: auto;
    margin: 0.5em 0;
  }
</style>
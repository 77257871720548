<template>
  <div>
    <h2>BeamNG</h2>

    <p>I make mods for BeamNG.drive. If you don't know what that is, then check it out here: <a href = "https://www.beamng.com/game/">BeamNG.drive</a></p>

    <h3>Gull Coast</h3>
    <figure class = "module module-large position-relative">
      <img src = "/images/beamng/gull-coast.jpg" alt = "Gull Coast map mod"/>
      <a href = "https://www.beamng.com/resources/gull-coast.5294/" target = "_blank" class = "button button-strong corner-br">Download</a>
    </figure>
    
    <p>My first and only map mod for BeamNG. Gull Coast is a 1 km by 1 km area, and has a main loop road, which is really fun to drive on. There is also a decent variety of points of interest. The mod also comes with 12 challenging scenarios, and an achievement system.</p>

    <h3>Covet Challenge</h3>
    <figure class = "module module-large position-relative">
      <img src = "/images/beamng/covet-challenge.jpg" alt = "Covet Challenge scenario mod"/>
      <a href = "https://www.beamng.com/resources/covet-challenge.24218/" target = "_blank" class = "button button-strong corner-br">Download</a>
    </figure>

    <p>Four races in one scenario! Race with the nimble Ibishu Covet MR Turbo, and try to dominate all of the races. There are some extra settings that you can apply, as well as difficult goal times to beat.</p>

    <h3>Roadblock Run</h3>
    <figure class = "module module-large position-relative">
      <img src = "/images/beamng/roadblock-run.jpg" alt="Roadblock Run scenario mod"/>
      <a href = "https://www.beamng.com/resources/roadblock-run-remake.21953/" target = "_blank" class = "button button-strong corner-br">Download</a>
    </figure>

    <p>Play as the police or suspect in this thrilling scenario! As the suspect, speed to the finish and break through roadblocks. As the police, catch the suspect with the help of fellow roadblock units. Comes with some extra settings to keep the fun going.</p>

    <h3>Catch Them All</h3>
    <figure class = "module module-large position-relative">
      <img src = "/images/beamng/catch-them-all.jpg" alt = "Catch Them All scenario mod"/>
      <a href = "https://www.beamng.com/resources/catch-them-all-remake.23345/" target = "_blank" class = "button button-strong corner-br">Download</a>
    </figure>

    <p>A remake of one of my very old scenarios. Here, you must chase and arrest three fleeing suspects in one attempt. It's quite a challenge, but it is satisfying to complete. Playable on four default maps.</p>

    <h3>Heated Pursuits</h3>
    <figure class = "module module-large position-relative">
      <img src = "/images/beamng/heated-pursuits.jpg" alt = "Heated Pursuits scenario mod"/>
      <a href = "https://www.beamng.com/resources/heated-pursuits-new-v2-0.3677/" target = "_blank" class = "button button-strong corner-br">Download</a>
    </figure>

    <p>A remake of my oldest scenario. As the police, chase and arrest the suspect in each unique scenario. There are 6 to do. Can you complete all of them quickly?</p>
  </div>
</template>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ScreenBox from '@/components/ScreenBox.vue'

const none = {
  mounted: (el) => {
    el.src = '/main/none.svg'
    el.style.backgroundImage = 'url("/main/none.svg")'
  }
}

createApp(App).use(store).use(router).component('ScreenBox', ScreenBox).directive('none', none).mount('#all')
<template>
  <div class = "module module-large">
    <div class = "module-top-image image-height" :style = "getBackgroundImage(data.image)">
      <div class = "module-top-bar gradient-bar-alt">
        <span class = "module-title">{{ data.title }}</span>
      </div>
    </div>
    <div class = "module-bottom-alt">
      <a class = "button button-strong" :href = "data.linkSm">Download</a>
      <a class = "button button-other" :href = "data.linkItg">Download (ITG)</a>
      <table class = "table sm-table">
        <tr>
          <th>Size</th>
          <td>{{ data.size }}</td>
        </tr>
        <tr>
          <th>Songs</th>
          <td>{{ data.songs }}</td>
        </tr>
        <tr>
          <th>Levels</th>
          <td>{{ levelRangeString }}</td>
        </tr>
      </table>
      <p>{{ data.text }}</p>
    </div>
  </div>
</template>

<script setup>
  import { defineProps, computed } from 'vue'

  const props = defineProps(['data'])

  function getBackgroundImage(image) {
    return {backgroundImage: 'url("' + image + '")'}
  }
  const levelRangeString = computed(() => {
    return (props.data.minLevel || '1') + ' - ' + (props.data.maxLevel || '12')
  })
</script>

<style scoped>
  .image-height {
    height: 11em;
  }
  .sm-table th, .sm-table td {
    width: 100px;
  }
  .module-bottom-alt {
    min-height: 15em;
    padding: 0 10px;
  }
</style>
<template>
  <div>
    <h2>Photography</h2>
    <p>This is my simple photography gallery. I use a Canon EOS Rebel T6i, with a Canon EF-S 18-135 mm lens.</p>
    <ContentArray class = "margin">
      <ThumbnailObject @open-screen-box = "setItem" v-for = "(item, index) in filteredPhotos" :data = "photos.data[item]" :num = "index" :key = "index"/>
    </ContentArray>
    <ScreenBox @close-screen-box = "deactivate" @screen-box-set = "setItem" v-if = "active" :data = "photos.data" :current = "current"/>
  </div>
</template>
<script setup>
  import { ref, reactive } from 'vue'

  import ContentArray from '@/components/ContentArray.vue'
  import ThumbnailObject from '@/components/ThumbnailObject.vue'
  import items from '@/../public/data/photography.json'

  var photos = reactive({data: items, total: items.length})
  const filteredPhotos = ref([]) // array of valid indexes

  function testFilteredPhotos() {
    filteredPhotos.value = []
    for (var i = 0; i < items.length; i++) {
      if (filteredPhotos.value) {
        filteredPhotos.value.push(i)
      }
    }
  }
  testFilteredPhotos()

  // remember to avoid using index for key
  // custom event that sets active and current item to display
  const active = ref(false)
  const current = ref(null)

  function deactivate() {
    active.value = false
  }

  //TODO: try to lazy load the previous or next image
  function setItem(num) {
    active.value = num >= 0 && num < items.length
    current.value = num
  }
</script>
<template>
  <div>
    <h2>BPM Tapper</h2>

    <div class = "module module-full padding">
      <p>Click the button for each beat that you hear in your current music! (Or, tap with Spacebar.)</p>
      <div class = "container">
        <div class = "app-input">
          <a id = "beat-button" class = "button button-strong button-big-square" @click = "addBeat()"></a>
          <br/>
          <a class = "button button-other button-wide" @click = "reset()">Reset</a>
        </div>
        <div class = "app-results">
          <table class = "app-table">
            <tr>
              <th class = "list-title tip-parent"><span class = "tip">Beat Count</span>Beats</th><td>{{beatData.length}}</td>
            </tr>
            <tr>
              <th class = "list-title tip-parent"><span class = "tip">Beats Per Minute</span>BPM</th><td>{{displayBeatsPerMinute}}</td>
            </tr>
            <tr>
              <th class = "list-title tip-parent"><span class = "tip">Beats Per Second</span>BPS</th><td>{{displayBeatsPerSecond}}</td>
            </tr>
          </table>
          <input type = "checkbox" id = "rounded" name = "rounded" v-model = "rounded" :value = "rounded"/>
          <label for = "rounded"> Show rounded results</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, reactive, computed } from 'vue'
  const beatsPerMinute = ref(0)
  const beatsPerSecond = ref(0)
  const rounded = ref(false)
  var beatData = reactive([])
  var keyIsDown = false

  const displayBeatsPerMinute = computed(() => {
    return rounded.value ? Math.round(beatsPerMinute.value) : beatsPerMinute.value.toFixed(3)
  })

  const displayBeatsPerSecond = computed(() => {
    return rounded.value ? Math.round(beatsPerSecond.value) : beatsPerSecond.value.toFixed(3)
  })

  function reset() {
    beatData = reactive([])
    beatsPerMinute.value = 0
    beatsPerSecond.value = 0
  }

  function animateBeat() {
    document.getElementById('beat-button').style.backgroundSize = "75%"
    setTimeout(() => {
      document.getElementById('beat-button').style.backgroundSize = "100%"
    }, 100)
  }

  function addBeat() {
    const date = new Date()
    beatData.push(date.getTime())
    animateBeat()

    var total = 0
    if (beatData.length <= 1) return
    for (var i = 0; i < beatData.length - 1; i++) {
      total = total + (beatData[i + 1] - beatData[i]) * 1e-3
    }
    var secondsPerBeat = total / (beatData.length - 1)
    beatsPerSecond.value = 1 / secondsPerBeat
    beatsPerMinute.value = beatsPerSecond.value * 60
  }

  addEventListener("keydown", (event) => {
    if (event.code == "Space") {
      event.preventDefault()
      if (!keyIsDown) addBeat()
      keyIsDown = true
    }
  })

  addEventListener("keyup", () => keyIsDown = false)
</script>

<style scoped>
  .padding {
    padding: 0.5em 10px;
  }
  .app-table {
    font-size: 2em;
    line-height: 1.2em;
  }
  .app-table th, .app-table td {
    padding-right: 10px;
    text-align: left;
  }
  .button-big-square {
    width: 120px;
    height: 120px;
    margin: 0;
    background-image: url("@/assets/icons/beat.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transition: background-size 0.1s;
  }
  .button-wide {
    width: 120px;
    margin: 0.5em 0;
  }
  #rounded {
    margin-top: 1em;
  }
  @media screen and (max-width: 600px) {
    .button-big-square {
      min-width: 80px;
      width: 80px;
      height: 80px;
    }
    .button-wide {
      min-width: 80px;
      width: 80px;
    }
    .app-table {
      font-size: 1.5em;
    }
  }
</style>
<template>
  <div>
    <h2>About</h2>
    <figure>
      <img src="/images/main/about-splash.jpg" alt="The gull of Gullbase"/>
    </figure>
		<p>Hello there! Nice to have you here.</p>
    <p>Introduction time, here we go. My name is Thomas, but you can call me Gull if you want. I'm around 30 years old, and I live on the west coast of Canada. I enjoy working within the digital world, including programming, design, and photography. I work as a junior developer for the softbody simulation software and game, BeamNG.drive.</p>
    <p>Outside of the digital world, I enjoy cooking, craft beer, hiking, birdwatching, and kayaking. Some of these interests may eventually end up being featured as new sections on this site. I'm looking forward to new adventures all the time.</p>
    <p>By the way, this site has existed before. I think this is the 4th revision of it. Let's see if I can keep building it without losing interest!</p>
  </div>
</template>
